const BaseURL = 'https://app-end.mujeres360.org/api';

const titles = {
  siteName: 'MUJERES 360',
}

const logos = {
  logoTopbar: 'logo_main.svg',
  logoLogin: 'logo_main_white_background.svg',
  backgroundLogin: 'login_background.jpg',
}

export {
  BaseURL,
  titles,
  logos
}
